import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { RenderingSubsection } from '@breathelife/questionnaire-engine';
import { PdfSubsectionHeader, PdfFieldText, ReactHtmlParser } from '@breathelife/ui-components';

import { FieldGroups } from './FieldGroups';

type Props = {
  subsections: RenderingSubsection[];
};

export const SUBSECTION_TEST_IDS = {
  SUBSECTION_TITLE: 'Subsection:subsectionTitle',
  SUBSECTION_TEXT: 'Subsection:subsectionText',
};

const SubsectionContainer = styled.div<{ pageBreak: boolean }>`
  ${({ pageBreak }) =>
    pageBreak
      ? css`
          break-before: page;
        `
      : css`
          page-break-inside: avoid;
        `}
`;

export function Subsections(props: Props): ReactElement | null {
  const { subsections } = props;

  return (
    <Fragment>
      {subsections.map((subsection) => {
        const {
          title: subsectionTitle,
          text: subsectionText,
          visible,
          id,
          questions: fieldGroups,
          pageBreakSubSectionInPdf: breakBefore,
        } = subsection;
        if (!visible) return null;

        return (
          <SubsectionContainer key={id} pageBreak={breakBefore ?? false}>
            <Box mb={1} mt={2}>
              <PdfSubsectionHeader data-testid={SUBSECTION_TEST_IDS.SUBSECTION_TITLE}>
                <ReactHtmlParser html={subsectionTitle} />
              </PdfSubsectionHeader>
              {subsectionText && (
                <Box mb={2} mt={-1}>
                  <PdfFieldText data-testid={SUBSECTION_TEST_IDS.SUBSECTION_TEXT}>
                    <ReactHtmlParser html={subsectionText} />
                  </PdfFieldText>
                </Box>
              )}
            </Box>
            <FieldGroups fieldGroups={fieldGroups} />
          </SubsectionContainer>
        );
      })}
    </Fragment>
  );
}
