import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';

import { getAllSections } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';

import usePdfContext from '../../../../Context/usePdfContext';
import { filterSectionsByPdfType } from '../../../../helpers/filterSectionsByPdfType';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import {
  SectionContainer,
  Separator,
  Title,
} from '../../ComponentVariants/ComponentVariantsB/Styles/PdfVariantBStyles';
import { Subsections } from './Subsections';
import { ReactHtmlParser } from '@breathelife/ui-components';

export const QUESTIONNAIRE_TEST_IDS = {
  SECTION_TITLE: 'Questionnaire:sectionTitle',
};

export function Questionnaire(): ReactElement {
  const { renderingQuestionnaire, pdfType, componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { enableSectionTitles = false } = questionnaireSettings;

  const sections = getAllSections(renderingQuestionnaire);

  const sectionsFilteredByPdfType = filterSectionsByPdfType(sections, pdfType);

  return (
    <div>
      {sectionsFilteredByPdfType.map((section) => {
        const { title: sectionTitle, visible, id } = section;
        if (!visible) return null;

        return (
          <SectionContainer key={id}>
            <Box mb={5}>
              {enableSectionTitles && sectionTitle && (
                <Fragment>
                  <Title data-testid={QUESTIONNAIRE_TEST_IDS.SECTION_TITLE}>
                    <ReactHtmlParser html={sectionTitle} />
                  </Title>
                  <Separator />
                </Fragment>
              )}
              <Subsections section={section} />
            </Box>
          </SectionContainer>
        );
      })}
    </div>
  );
}
