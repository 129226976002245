import { ReactElement, Fragment } from 'react';

import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';
import { PdfFieldText, PdfPaddedGridItem, ReactHtmlParser } from '@breathelife/ui-components';

import usePdfContext from '../../../Context/usePdfContext';
import { optionIsSelected } from '../../../helpers/fieldHelpers';
import getComponentSettings from '../../../helpers/getComponentSettings';
import { QuestionAnswerGroupA } from '../../PdfComponents/ComponentVariants/ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';

type Props = {
  field: RenderingField;
};

export function Dropdown(props: Props): ReactElement | null {
  const { field } = props;
  const { componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { displayAllOptions, optionDisplayLimit = 0 } = questionnaireSettings;

  const options = (field as RenderingOptionField).options;

  if (!options) {
    throw new Error('There are no options to display a Dropdown');
  }

  return (
    <Fragment>
      <PdfPaddedGridItem item xs={6} data-testid='Dropdown'>
        {displayAllOptions && field?.title && (
          <PdfFieldText>
            <ReactHtmlParser html={field.title} />
          </PdfFieldText>
        )}
        {!!options.length &&
          options.map((option) => {
            const selectedOption = optionIsSelected(option.id, field?.value);

            if (!displayAllOptions && selectedOption) {
              return (
                <QuestionAnswerGroupA
                  key={option.id}
                  fieldText={field.title}
                  labelText={field.text}
                  value={option.text}
                />
              );
            }

            if (displayAllOptions) {
              if (options.length <= optionDisplayLimit) {
                return (
                  <QuestionAnswerGroupA
                    key={option.id}
                    value={option.text}
                    isOptionGroup
                    isUnselectedOption={!selectedOption}
                  />
                );
              } else {
                if (selectedOption) {
                  return <QuestionAnswerGroupA key={option.id} value={option.text} />;
                }
              }
            }

            return null;
          })}
      </PdfPaddedGridItem>
    </Fragment>
  );
}
