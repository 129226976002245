import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';

import { GridContainer, GridItem, GridPropsWithText, SequenceNumber, VariantText } from '../Styles/FieldVariantBStyles';
import { ReactHtmlParser } from '@breathelife/ui-components';

export function LabelGrid(props: GridPropsWithText): ReactElement {
  const { text, fieldIndex, subtitle, ...gridProps } = props;
  return (
    <GridContainer container direction='row' {...gridProps}>
      <GridItem item xs={9}>
        <SequenceNumber>{fieldIndex}</SequenceNumber>
        <Grid>
          <VariantText>
            <ReactHtmlParser html={text} />
          </VariantText>
          {subtitle && (
            <VariantText grey={80}>
              <ReactHtmlParser html={subtitle} />
            </VariantText>
          )}
        </Grid>
      </GridItem>
    </GridContainer>
  );
}
