import { ReactElement, Fragment } from 'react';

import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';
import { PdfFieldText, PdfPaddedGridItem, ReactHtmlParser } from '@breathelife/ui-components';

import usePdfContext from '../../../Context/usePdfContext';
import { optionIsSelected } from '../../../helpers/fieldHelpers';
import getComponentSettings from '../../../helpers/getComponentSettings';
import { QuestionAnswerGroupA } from '../../PdfComponents/ComponentVariants/ComponentVariantsA/Styles/PdfQuestionAnswerGroupA';

type Props = {
  field: RenderingField;
};
export function CheckboxField(props: Props): ReactElement | null {
  const { field } = props;
  const { componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { displayAllOptions, optionDisplayLimit = 0 } = questionnaireSettings;

  const options = (field as RenderingOptionField).options;

  if (!options) {
    throw new Error('There are no options to display a CheckboxField');
  }

  return (
    <Fragment>
      <PdfPaddedGridItem item xs={6} data-testid='CheckboxField'>
        {displayAllOptions && field.title && (
          <PdfFieldText>
            <ReactHtmlParser html={field.title} />
          </PdfFieldText>
        )}
        {!!options.length &&
          options.map((option) => {
            const unselectedOption = !optionIsSelected(option.id, field.value);
            if (displayAllOptions && options.length <= optionDisplayLimit) {
              return (
                <QuestionAnswerGroupA
                  key={option.id}
                  value={option.text}
                  isOptionGroup
                  isUnselectedOption={unselectedOption}
                />
              );
            } else {
              if (unselectedOption) {
                return null;
              }
              return (
                <QuestionAnswerGroupA
                  key={option.id}
                  fieldText={field.title}
                  labelText={field.text}
                  value={option.text}
                  isOptionGroup
                />
              );
            }
          })}
      </PdfPaddedGridItem>
    </Fragment>
  );
}
