import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';

import { getAllSections } from '@breathelife/questionnaire-engine';
import { DynamicPdfComponentName } from '@breathelife/types';
import { PdfQuestionnaireSectionHeader, ReactHtmlParser } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { filterSectionsByPdfType } from '../../../../helpers/filterSectionsByPdfType';
import getComponentSettings from '../../../../helpers/getComponentSettings';
import { Subsections } from './Subsections';

export const QUESTIONNAIRE_TEST_IDS = {
  SECTION_TITLE: 'Questionnaire:sectionTitle',
};

export function Questionnaire(): ReactElement | null {
  const { renderingQuestionnaire, pdfType, componentSelection = [] } = usePdfContext();

  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { enableSectionTitles = false, avoidSubsectionPageBreak = true } = questionnaireSettings;

  const pageBreakInside = avoidSubsectionPageBreak ? 'avoid' : 'auto';

  const sections = getAllSections(renderingQuestionnaire);
  const sectionsFilteredByPdfType = filterSectionsByPdfType(sections, pdfType);

  return (
    <Fragment>
      {sectionsFilteredByPdfType.map((section) => {
        const { title: sectionTitle, visible, id, subsections } = section;
        if (!visible) return null;

        const isSubsectionExists = subsections.some((subsection) => subsection.visible === true);
        if (!isSubsectionExists) return null;

        return (
          <div key={id} style={{ pageBreakInside: `${pageBreakInside}` }}>
            <Box mb={5}>
              {enableSectionTitles && (
                <PdfQuestionnaireSectionHeader data-testid={QUESTIONNAIRE_TEST_IDS.SECTION_TITLE}>
                  <ReactHtmlParser html={sectionTitle} />
                </PdfQuestionnaireSectionHeader>
              )}
              <Subsections subsections={subsections} />
            </Box>
          </div>
        );
      })}
    </Fragment>
  );
}
