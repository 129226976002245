import { ReactElement } from 'react';

import { Grid } from '@breathelife/mui';
import { RenderingAgreeField, RenderingField } from '@breathelife/questionnaire-engine';

import usePdfContext from '../../../Context/usePdfContext';
import { translate } from '../../../Localization/Localizer';
import { AnswerGrid } from './Helpers/AnswerGrid';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';
import { ReactHtmlParser } from '@breathelife/ui-components';

type Props = {
  field: RenderingField;
  fieldIndex: string;
};

export function AgreeField(props: Props): ReactElement {
  const { field, fieldIndex } = props;

  const { application } = usePdfContext();

  const locale = application.lang;

  const modalText = (field as RenderingAgreeField).modalText ?? '';
  const valueText = String(field.value) ? translate('yes', { locale }) : translate('no', { locale });

  return (
    <GridContainer container direction='row'>
      <GridItem item xs={9}>
        <SequenceNumber>{fieldIndex}</SequenceNumber>
        <Grid>
          <VariantText>
            <ReactHtmlParser html={modalText} />
          </VariantText>
          {field.text && (
            <VariantText grey={80}>
              <ReactHtmlParser html={field.text} />
            </VariantText>
          )}
        </Grid>
      </GridItem>
      <AnswerGrid item xs={3} text={valueText} />
    </GridContainer>
  );
}
