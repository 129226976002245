import { ReactElement, Fragment } from 'react';

import { Grid } from '@breathelife/mui';
import { RenderingField, RenderingOptionField } from '@breathelife/questionnaire-engine';

import usePdfContext from '../../../Context/usePdfContext';
import { translate } from '../../../Localization/Localizer';
import { GridContainer, GridItem, SequenceNumber, VariantText } from './Styles/FieldVariantBStyles';
import { ReactHtmlParser } from '@breathelife/ui-components';

type Props = {
  field: RenderingField;
  fieldIndex: string;
};

export function Radio(props: Props): ReactElement {
  const { field, fieldIndex } = props;

  const { application } = usePdfContext();

  const locale = application.lang;

  const options = (field as RenderingOptionField).options;
  const fieldHasBooleanValue = typeof field.value === 'boolean';

  let valueText: string = '';

  if (options) {
    const relevantOption = options.find((option) => option.id === field.value);
    valueText = relevantOption?.text ?? '';
  } else if (fieldHasBooleanValue) {
    valueText = String(field.value) ? translate('yes', { locale }) : translate('no', { locale });
  }

  return (
    <Fragment>
      {field.title ? (
        <GridContainer container direction='row'>
          <GridItem item xs={9}>
            <SequenceNumber>{fieldIndex}</SequenceNumber>
            <Grid>
              <VariantText>
                <ReactHtmlParser html={field.title} />
              </VariantText>
              {field.text && (
                <VariantText grey={80}>
                  <ReactHtmlParser html={field.text} />
                </VariantText>
              )}
            </Grid>
          </GridItem>
          <Grid item xs={3} style={{ padding: '0px' }}>
            <VariantText align='right' weight={800}>
              {valueText}
            </VariantText>
          </Grid>
        </GridContainer>
      ) : (
        <Grid item xs={3} style={{ padding: '0px' }}>
          <VariantText align='right' weight={800}>
            {valueText}
          </VariantText>
        </Grid>
      )}
    </Fragment>
  );
}
