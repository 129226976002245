import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';

import { RenderingSection } from '@breathelife/questionnaire-engine';

import {
  Title,
  P,
  PdfVariantFieldGroupHeader,
  Separator,
  SubSectionContainer,
} from '../../ComponentVariants/ComponentVariantsB/Styles/PdfVariantBStyles';
import { FieldGroups } from './FieldGroups';
import { ReactHtmlParser } from '@breathelife/ui-components';

type SubsectionsProps = {
  section: RenderingSection;
};

export function Subsections(props: SubsectionsProps): ReactElement {
  const { section } = props;
  const { subsections, title: sectionTitle } = section;

  return (
    <div>
      {subsections.map((subsection) => {
        if (!subsection.visible) return null;
        const { pageBreakSubSectionInPdf } = subsection;
        return (
          <SubSectionContainer key={subsection.id} pageBreak={pageBreakSubSectionInPdf ?? false}>
            <Box mb={3}>
              {!sectionTitle ? (
                <Fragment>
                  <Title>
                    <ReactHtmlParser html={subsection.title} />
                  </Title>
                  <Separator />
                </Fragment>
              ) : (
                <PdfVariantFieldGroupHeader>
                  <ReactHtmlParser html={subsection.title} />
                </PdfVariantFieldGroupHeader>
              )}
              {subsection.text && (
                <Box mb={2}>
                  <P>
                    <ReactHtmlParser html={subsection.text} />
                  </P>
                </Box>
              )}
              <FieldGroups fieldGroups={subsection.questions} />
            </Box>
          </SubSectionContainer>
        );
      })}
    </div>
  );
}
